import styled from "styled-components"
import Page from "../../components/Pages/Page"
import React from "react"
import colors from "../../colors"
import AllNews from "../../components/News/AllNews"

const News = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  padding: 0px 5%;
  width: 70%;
  align-self: center;

  @media (max-width: 980px) {
    width: 100%;
  }

  h1 {
    font-family: 'Poppins-Bold';
    @media (max-width: 425px) {
      font-size: 1.6rem;
    }
    @media (max-width: 320px) {
      font-size: 1.4rem;
    }
  }
  img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
  button {
    background-color: ${colors.primary2};
    padding: 10px 30px;
    border-radius: 8px;
    border: none;
    color: ${colors.white};
    font-family: 'Poppins-Bold';
    margin-top: 12px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 100%;
  }
  .input {
    color: ${colors.primary2};
    margin-top: 12px;
    padding: 10px 20px;
    border: 2px solid ${colors.primary2};
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .icon {
    color: ${colors.primary2};
    font-size: 1.3rem;
    margin-right: 8px;
  }

  input {
    border: none;
    color: ${colors.primary2};
    width: 100%;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
`
const SubscribedNewsletter = () => {

  return (
    <Page
      hideHeader
      hideFooter
      title={`Newsletter`}
      description={`Uma newsletter para tornar mais claro o seu caminho para o sucesso e desenvolvimento pessoal por meio de conteúdos sobre hábitos, produtividade e metas.`}
      pageId={"newsletter"}
      forceTheme={"default"}
    >
      <News>
        <img src={require("../../assets/images/logo.png")} />
        <h1> 🧠 Pílulas do Flynow 🧠</h1>
        <br />
            <br />
            <h3>Boa! Você foi cadastrado(a) na nossa Newsletter!</h3>
            <br />
            {/* <h4>O que esperar da Pílulas do Flynow 🤔</h4> */}
            <p>
              Toda <b>segunda-feira</b>, você receberá no seu e-mail{" "}
              <b>uma reflexão</b> sobre algum tema que abrange o desenvolvimento
              pessoal e uma frase para ponderar durante a semana.{" "}
            </p>
            <p>
              💡 Você pode conferir nossas newsletters anteriores abaixo:👇🏻
            </p>
            <br/>
        <AllNews />
      </News>
    </Page>
  )
}

export default SubscribedNewsletter
