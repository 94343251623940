import { format, isAfter, parseISO } from 'date-fns';
import { Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../colors';

const query = graphql`
  {
    allStrapiNewsletters(filter: {}, sort: { order: DESC, fields: date }) {
      nodes {
        content
        created_at
        date
        id
        title
        visible
        url
      }
    }
  }
`

const News = styled.div`
  h3{
    text-align: center;
    color: ${colors.primary};
    align-items: center;
    margin-bottom: 1rem;
  }
  h4{
    color: ${colors.primary};
    margin-bottom: 10px;
  }
  .row{
    display: flex;
    padding: 0.6rem 1rem;
    color: #454545;
    background-color: #f7f7f7;
    border-radius: 6px;
    margin-bottom: 10px;
    &:hover{
      color: #000 !important;
      background-color: #eee;
    }
  }
  .news-date{
    font-size: 14px;
    font-family: 'Poppins-Bold';
  }
  .news-title{
    margin-left: 5px;
  }
  @media (max-width: 425px) {
    .news-date{
      font-size: 10px;
    }
    .news-title{
      font-size: 14px;
      margin-left: 5px;
    }
    .row{
      padding: 0.6rem 0.7rem;
    }
  }
`

const AllNews = () => {
  const {
    allStrapiNewsletters: { nodes },
  } = useStaticQuery(query)
  const [news, setNews] = useState(nodes.filter(post => isAfter(new Date(), parseISO(post.date))))

  const data = news.reduce((acc, item) => {
    const year = format(parseISO(item.date), 'yyyy')
    return({
    ...acc,
    [year]: [
      ...(acc[year] || []), 
      item,
    ]
  })}, {})

  const list = Object.keys(data).map(key => ({year: key, data: data[key]}));

  return (
    <News>
      <h3>Todas as Newsletters</h3>

      {list.map(year=> (
        <div>   
          <h4>{year.year}</h4>
          {year.data.map(item=> (
            <Link to={'/newsletter/'+ item.url} replace>
            <div className='row'>
              <span className='news-date'>[{format(parseISO(item.date), "dd 'de' MMM")}]</span>
              <span className='news-title'>{item.title}</span>
            </div>
          </Link>
          ))}
        </div>
      ))
      }
      <br/>
      <br/>
    </News>
  )
}

export default AllNews;